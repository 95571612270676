<!-- pageName -->
<template>
  <div class="portal_details">
    <component v-bind:is="currentFrame"></component>
  </div>
</template>

<script>
// import ErshiFrame from '../ershi/components/ershiFrame.vue'
const YzsyPcFrameDetails = () => import('../../yzsy/detail.vue')

export default {
  components: {
    YzsyPcFrameDetails,
  },
  props: {},
  data() {
    return {
      pcmodel: '',
      currentFrame: '',
    }
  },
  computed: {},
  watch: {},
  methods: {
  },
  created() {
    this.pcmodel = JSON.parse(localStorage.getItem('pcmodel'))
    this.currentFrame = 'YzsyPcFrameDetails'
  },
  mounted() {}
}
</script>

<style lang="less" scoped>
/* @import url(); 引入css类 */

</style>
